import _ from "lodash";
import { Ref } from "vue";
import clickSound from "../assets/sounds/senexis_INTERFACE_CLICK.mp3";
import { useCurrentCareDay } from "~/composables/useStates.js";
import { CareDay, CareDayProperty } from "~/types/careDay.js";

const DEFAULT_DEBOUNCE_TIME = 200;
const DEBOUNCE_OPTIONS = { leading: true, trailing: false };

const currentCareDay: Ref<CareDay | null> = useCurrentCareDay();

const click = new Audio(clickSound);

const goBack = () => {
  useRouter().back();
};

const goBackPreview = () => {
  const route = useRoute();
  const { params, query } = route;

  if (query.preview && Object.keys(params).length > 1) {
    useRouter().go(-2);
  } else {
    goBack();
  }
};

const useGoToItem = (property: CareDayProperty) => (itemId: string) => {
  const route = useRoute();
  const { path, fullPath, query } = route;

  // check for double click error
  if (path.endsWith(itemId) || path.endsWith(`${itemId}/`)) {
    return; // we are already at the target
  }

  const mirrorDataMap = currentCareDay.value?.mirrorData;
  const mirrorDataFromId = mirrorDataMap?.[property];

  if (mirrorDataFromId) {
    const careDayId = route.params.id;

    const urlParts = fullPath.split(careDayId as string);
    const urlPrefix = urlParts[0];
    const urlSuffix = urlParts[1];
    const queryParam = `?mirroredTo=${careDayId}`;

    const navTarget = `${urlPrefix}${mirrorDataFromId}${urlSuffix}/${itemId}${queryParam}`;
    navigateTo(navTarget);
  } else {
    let navTarget = `${path}/${itemId}`;

    if (Object.keys(query).length > 0) {
      const searchParams = new URLSearchParams(query as Record<string, string>).toString();
      navTarget += `?${searchParams}`;
    }

    navigateTo(navTarget);
  }
};

const goHome = () => {
  const route = useRoute();
  // mirroredTo is set, when the item we are looking at is mirrored from another CareDay
  // currentCareDay should then be the original one, not the mirrored one
  const id = route.query.mirroredTo || currentCareDay.value?.id;

  if (id) {
    navigateTo(`/kiosk/${id}`);
  } else {
    goBack();
    goBack();
  }
};

export const useGoToItemDebounced = (property: CareDayProperty) => _.debounce(useGoToItem(property), DEFAULT_DEBOUNCE_TIME, DEBOUNCE_OPTIONS);
export const useGoBackDebounced = () => _.debounce(goBack, DEFAULT_DEBOUNCE_TIME, DEBOUNCE_OPTIONS);
export const useGoBackPreviewDebounced = () => _.debounce(goBackPreview, DEFAULT_DEBOUNCE_TIME, DEBOUNCE_OPTIONS);
export const useGoHomeDebounced = () => _.debounce(goHome, DEFAULT_DEBOUNCE_TIME, DEBOUNCE_OPTIONS);

export const useDebounce = (fn: Function) => _.debounce(fn as any, DEFAULT_DEBOUNCE_TIME, DEBOUNCE_OPTIONS);

class ButtonManager {
  readonly handleClick: () => void;
  readonly isPressedDown: Ref<boolean>;

  private readonly handleClickFn: () => void;

  constructor (handleClickFn: () => void) {
    this.isPressedDown = ref(false);
    this.handleClickFn = useDebounce(() => {
      click.play();
      handleClickFn();
    });

    this.handleClick = () => {
      this.unsetPressed();
      this.handleClickFn();
    };
  }

  setPressed = () => {
    this.isPressedDown.value = true;
  };

  unsetPressed = () => {
    this.isPressedDown.value = false;
  };
}
export const useButton = (handleClick: () => void) => new ButtonManager(handleClick);
